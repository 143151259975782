import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSet(handle: "header") {
        ... on Craft_header_GlobalSet {
          headerLinks {
            ... on Craft_headerLinks_link_BlockType {
              uid
              headLink {
                url
                text
              }
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    url
                    text
                  }
                }
              }
            }
          }
          button: link0 {
            url
            text
          }
          login: link1 {
            url
            text
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const { headerLinks, button, login } = globalSet;
  const nav = headerLinks?.map(links => {
    return {
      ...links?.headLink,
      children: links?.sublinks?.map(sub => sub?.sublink),
    };
  });
  nav.push({ ...button, button: true });
  return {
    nav,
    login,
  };
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
